import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@fullstakk/fms-frontend-theme'

import Seo from '../lib/components/Application/Seo'
import Service from '../lib/utility/Service'


/**
 * Encapsulates the 404 page of the frontend.
 */
export default class Page extends React.Component {
    /**
     * Renders the page.
     *
     * @returns {JSX.Element} Rendered page
     */
    render() {
        const App = Service.instance('App')
        const Auth = Service.instance('Auth')
        const content = this.props.data.markdownRemark
        return (
            <Layout
                theme='HeaderMainFooter'
                title={App.getName()}
                home={{link: App.getHomePageUrl(), title: 'Go back to Fullstakk Marketing Suite'}}
                user={Auth.isLoggedIn() ? Auth.getUser() : null}
                onLogOut={() => App.signOut()}
                mainClassName='wrapper-page'
            >
                <Seo title={content.frontmatter.title} description={content.excerpt} />
                <h1>{content.frontmatter.title}</h1>
                <article dangerouslySetInnerHTML={{ __html: content.html }} />
            </Layout>
        )
    }
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      excerpt
    }
  }
`
